<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="alarms"
      :loading="alarmStatus.loading"
      :items-per-page="50"
      class="elevation-1"
      @click:row="clickedRow"
    >
      <template v-slot:[`item.statusColor`]="{ item }">
        <v-sheet
          :color="getStatusColor(item)"
          height="100%"
          min-height="25px"
          width="100%"
          min-width="25px"
          max-width="50px"
          outlined
          rounded
        ></v-sheet>
      </template>

      <template v-slot:[`item.priority`]="{ item }">
        {{ getPriorityChar(item.priority) }}
      </template>

      <template v-slot:[`item.limitLow`]="{ item }">
        {{ item.limitLow != null ? item.limitLow : "N/A" }}
      </template>

      <template v-slot:[`item.limitHigh`]="{ item }">
        {{ item.limitHigh != null ? item.limitHigh : "N/A" }}
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-chip :color="getColorFromBool(item.active)" outlined>{{
          item.active ? $t("common.active") : $t("common.notActive")
        }}</v-chip>
      </template>

      <template v-slot:[`item.activeAlarms.length`]="{ item }">
        <v-chip
          :color="item.activeAlarms.length > 0 ? 'error' : 'success'"
          outlined
          @click.stop="$router.push('/alarm/active')"
          >{{ item.activeAlarms.length }}</v-chip
        >
      </template>
      <template v-slot:[`item.advanced`]="{ item }">
        <v-chip :color="item.advanced ? 'success' : 'error'" outlined>
          <v-icon :color="item.advanced ? 'success' : 'error'">{{
            item.advanced ? "mdi-check" : "mdi-window-close"
          }}</v-icon>
        </v-chip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-expansion-panels>
      <v-expansion-panel @click="loadAlarmHistory()">
        <v-expansion-panel-header class="pb-0">
          <v-card-title class="headline mb-2">
            {{ $t("alarm.history") }}
            <v-spacer></v-spacer>
          </v-card-title>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-text class="pa-0 ma-0">
            <v-data-table
              :items="alarmActives"
              :headers="historyHeaders"
              sort-by="updatedAt"
              :sort-desc="true"
              class="logtable"
            >
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ humanDate(item.createdAt, "dd MMM - HH:mm:ss") }}
              </template>

              <template v-slot:[`item.updatedAt`]="{ item }">
                {{ humanDate(item.updatedAt, "dd MMM - HH:mm:ss") }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { Roles } from "@/_helpers/Role";

export default {
  name: "AlarmList",
  data() {
    return {
      alarms: [],
      history: false,
      filteredActives: [],
      keyLabels: [],
      headers: [
        { text: "Status", value: "statusColor" },
        { text: this.$t("alarm.fields.name"), value: "name" },
        { text: this.$t("alarm.fields.priority"), value: "priority" },
        {
          text: this.$t("alarm.fields.limitLow"),
          value: "limitLow",
        },
        { text: this.$t("tag.fields.latestValue"), value: "latestValue" },
        { text: this.$t("alarm.fields.limitHigh"), value: "limitHigh" },
        { text: this.$t("alarm.fields.tag"), value: "tag.name" },
        { text: this.$t("alarm.fields.tagDataKey"), value: "label" },
        { text: this.$t("alarm.fields.advanced"), value: "advanced" },
        { text: this.$t("alarm.fields.active"), value: "active" },
        {
          text: this.$t("alarm.fields.activeAlarms"),
          value: "activeAlarms.length",
        },
      ],
      historyHeaders: [
        { text: this.$t("alarm.fields.triggered"), value: "createdAt" },
        { text: this.$t("alarm.fields.reset"), value: "updatedAt" },
        { text: this.$t("alarm.fields.name"), value: "alarm.name" },
        { text: this.$t("alarm.fields.message"), value: "alarm.message" },
        { text: this.$t("alarm.fields.user"), value: "user.email" },
        { text: this.$t("alarm.fields.value"), value: "value" },
      ],
    };
  },
  computed: {
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),
    ...mapState("alarms", {
      alarmStatus: "status",
      storeAlarms: "alarms",
      alarmActives: "activeAlarms",
    }),
    ...mapState("tagData", ["currentTagData"]),

    Roles() {
      return Roles;
    },
  },
  methods: {
    ...mapActions("alarms", [
      "getAlarms",
      "setCurrentAlarm",
      "getAlarmHistory",
    ]),
    ...mapActions("tagData", { getTagData: "getCurrentTagdata" }),

    getColorFromBool(bool) {
      return bool ? "success" : "error";
    },

    getStatusColor(item) {
      if (!item.active) return "grey";

      if (item.activeAlarms.length > 0) return "red";

      if (
        (item.limitHigh == null && item.latestValue >= item.limitLow) ||
        (item.limitLow == null && item.latestValue <= item.limitHigh) ||
        (item.latestValue <= item.limitHigh &&
          item.latestValue >= item.limitLow)
      )
        return "success";

      return item.advanced ? "success" : "warning";
    },

    loadAlarmHistory() {
      if (!this.history) {
        this.getAlarmHistory();
        this.history = true;
      } else this.history = false;
    },

    getPriorityChar(val) {
      switch (val) {
        case 0:
          return "A";
        case 1:
          return "B";
        case 2:
          return "C";
        default:
          return "N/A";
      }
    },

    async addLatestValues() {
      for (let a = 0; a < this.alarms.length; a++) {
        if (this.alarms[a].advanced == false) {
          await this.getTagData({
            tagId: this.alarms[a].tag.deveui,
            tagKey: this.alarms[a].tagDataKey,
          });

          // We need to use set function to maintain reactivity
          let tmpAlarm = this.alarms[a];
          tmpAlarm.latestValue = this.currentTagData.value;
          this.$set(this.alarms, a, tmpAlarm);
        }
      }
    },

    async clickedRow(item) {
      await this.setCurrentAlarm(item);

      if (this.permitted("Alarm.Update"))
        this.$router.push(`/alarm/${item.alarmId}`);
    },
  },

  async created() {
    await this.getAlarms();
    this.alarms = this.storeAlarms.slice();
    await this.addLatestValues();
  },
};
</script>

<style>
</style>